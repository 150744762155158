<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="handleInviteDialogShow('')" :disabled="residueNumber <= 0">新增邀请</el-button>
        <div class="alert" :class="residueNumber > 10 ? 'primary' : 'error'" v-if="userType === USER_TYPE.SCHOOL && alertVisible">
            <div class="text">
                <i class="el-icon-info"></i>
                <span>当前可邀请的剩余名额为 <span class="number">{{residueNumber}}</span> 个</span>
            </div>
            <div class="close" @click="alertVisible = false">
                <i class="el-icon-close"></i>
            </div>
        </div>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :span="9">
                    <span class="list-title">邀请列表</span>
                </el-col>
                <el-col :span="7" >
                    <el-input class="circular-bead-input" placeholder="请输入用户名称" prefix-icon="el-icon-search" title="按回车键搜索" clearable
                        @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model='search.user_name' >
                    </el-input>
                </el-col>
                <el-col :span="7">
                    <el-input class="circular-bead-input" placeholder="请输入联系方式" prefix-icon="el-icon-search" title="按回车键搜索" clearable
                        @keyup.enter.native="pageChange(1)" @clear="pageChange(1)" v-model.number='search.user_phone' >
                    </el-input>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="inviteList"  tooltip-effect="dark" style="width: 100%" size="medium"
                    @selection-change="handleSelectionChange"  :row-key="getRowKey"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center">

                    <el-table-column type="selection" width="60" :reserve-selection="true" align="center"></el-table-column>
                    <el-table-column prop="user_name" label="用户名称" min-width="120" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="fz-bold" v-if="scope.row.status !== 200">{{scope.row.user_name}}</div>

                            <div class="fz-bold hover-text-colourful" v-else @click="routerChange(1,scope.row)">
                                {{scope.row.user_name}}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="user_phone" label="联系方式" min-width="120">
                        <template slot-scope="scope">
                            {{scope.row.user_phone}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="邀请状态" min-width="120">
                        <template slot-scope="scope">
                            <span class="remark" :class="`status-${scope.row.status}`">
                                {{scope.row.status | inviteStatusFilter}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="备注" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            {{scope.row.remark || '无'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" min-width="155">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="130" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="handleInviteDialogShow(scope.row)">
                                <el-tooltip placement="top" content="二维码">
                                    <i class="iconfont icon-qrcode" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDeleteInvite(scope.row.id)">
                                <el-tooltip placement="top" content="删除">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container with-multiple">
                <div class="left">
                    <div class="btn-area">
                        <el-button type="primary" icon="iconfont icon-delete" round plain :disabled="!multipleSelection.length"
                            @click="handleDeleteSelect">批量删除
                        </el-button>
                    </div>
                    <div class="selected-sum">
                        <span>已选中 {{multipleSelection.length || 0}} 条</span>
                    </div>
                </div>

                <el-pagination class="right" layout="total, sizes, prev, pager, next" background
                    @size-change="pageSizeChange"
                    @current-change="pageChange"
                    :current-page="currentPage"
                    :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
        <div class="dialog-invite">
            <el-dialog width="600px" :visible.sync = "dialogVisible"
                :close-on-click-modal="invite.id ? true : false"
                @close="handleInviteDialogClose">
                <div slot='title'>
                    <span class="el-dialog__title">{{(invite.id ? '查看' : '新增') + '邀请'}}</span>
                    <span class="alert-inner" :class="residueNumber > 10 ? 'primary' : 'error'" v-if="userType === USER_TYPE.SCHOOL">
                        <span class="text">
                            <i class="el-icon-info"></i>
                            <span>当前可邀请的剩余名额为 <span class="number">{{residueNumber}}</span> 个</span>
                        </span>
                    </span>
                </div>
                <div class="dialog-content" v-if="!invite.id">
                    <div class="content-floor">
                        <span class="key">姓名</span>
                        <el-input v-model="invite.user_name"></el-input>
                    </div>
                    <div class="content-floor">
                        <span class="key">联系方式</span>
                        <el-input v-model.number="invite.user_phone"></el-input>
                    </div>
                    <div class="content-floor">
                        <span class="key">备注</span>
                        <el-input v-model="invite.remark" maxlength="30" show-word-limit></el-input>
                    </div>
                </div>
                <div class="dialog-content" v-else>
                    <div class="inviteQrCode">
                        <div class="qrcode" id="qrcode"></div>
                    </div>
                    <div class="inviteLink">
                        <p>{{invite.inviteLink}}</p>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                    <div v-if="!invite.id">
                        <el-button class="cancel" size="medium" @click="handleInviteDialogClose">取消</el-button>
                        <el-button type="primary" size="medium" @click="handleInviteConfirm">创建邀请</el-button>
                    </div>
                    <div v-else>
                        <el-button class="cancel" size="medium" icon="el-icon-download" @click="handleSaveImg">保存图片</el-button>
                        <el-button type="primary" size="medium" icon="el-icon-copy-document" @click="handleCopyLink">复制链接</el-button>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import Core from '@/core';
import QRCode from "qrcodejs2"

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,

            multipleSelection: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            inviteList: [],

            invite: {
                id: 0,
                user_name: '',
                user_phone: '',

                inviteQrCodeImg: '',
                inviteLink: '',
            },
            search: {
                user_name: '',
                user_phone: '',
            },
            dialogVisible: false,
            alertVisible: true,
            residueNumber: 500,
        };
    },
    created() {
        this.getInviteList();
        if (this.userType === this.USER_TYPE.SCHOOL) {
            this.getResidueNumber();
        }
    },
    mounted() {},
    methods: {
        getRowKey(row) {
            return row.id;
        },
        handleSelectionChange(val) { // 表格选择
            this.multipleSelection = val;
        },
        routerChange(key, q = '') { // 路由改变
            switch (key) {
                case 1: // 查看详情
                    this.$router.push({
                        path: '/operate-quotient/record-detail',
                        query: {
                            id: q.customer_id,
                            record_id: q.record_id,
                            evaluation_id: q.evaluation_data_id,
                        }
                    });
                    break;
            }
        },
        pageChange(page) { // 页码改变
            this.currentPage = page;
            this.getInviteList();
        },
        pageSizeChange(size) { // 列表信息行数改变
            this.pageSize = size;
            this.getInviteList();
        },
        getResidueNumber() {
            Core.Api.School.detail().then((res) => {
                console.log("getResidueNumber -> res", res)
                this.residueNumber = res.school.record_invitation_count;
            });
        },
        getInviteList(type) { // 获取邀请列表
            Core.operationApi.Invite.paging(
                this.search.user_name,
                this.search.user_phone,
                this.currentPage,
                this.pageSize
            ).then((res) => {
                this.total = res.list.count;
                this.inviteList = res.list.list;
                // console.log("getInviteList -> this.inviteList", this.inviteList)
                if (type === 'new') {
                    let last = this.inviteList[0]
                    // console.log("getInviteList -> New", last)
                    this.invite.id = last.id
                    this.invite.user_name = last.user_name
                    this.invite.user_phone = last.user_phone
                    this.invite.inviteLink = `http://gda.school.rebuildsoft.com/#/enter?code=${last.code}`
                    this.$nextTick (function() {
                        this.qrcode(this.invite.inviteLink);
                    })
                }
            });
        },
        handleInviteDialogShow(item) {  // 显示弹框
            if (item) {
                this.invite.id = item.id
                this.invite.user_name = item.user_name;
                this.invite.user_phone = item.user_phone;
                this.invite.inviteLink = `http://gda.school.rebuildsoft.com/#/enter?code=${item.code}`;
                this.$nextTick (function() {
                    this.qrcode(this.invite.inviteLink);
                })
            }
            console.log("handleInviteDialogShow -> this.invite", this.invite)
            this.dialogVisible = true
        },
        handleInviteDialogClose() {   // 关闭弹框
            this.dialogVisible = false
            this.invite = {
                id: 0,
                user_name: '',
                user_phone: '',
                inviteQrCodeImg: '',
                inviteLink: '',
            },
            this.getInviteList()
        },
        handleInviteConfirm() {  // 确认创建邀请
            if(this.invite.user_name === '' || this.invite.user_phone === ''){
                return this.$message.error("请填写完整")
            } else if(!(/^1[3456789]\d{9}$/.test(this.invite.user_phone))){
                return this.$message.error("手机号码有误，请正确填写")
            }
            Core.operationApi.Invite.save(
                this.invite.id,
                this.invite.user_name,
                this.invite.user_phone,
                this.invite.remark,
            ).then((res) => {
                this.$message.success('创建成功');
                this.getInviteList('new');
                if (this.userType === this.USER_TYPE.SCHOOL) {
                    this.getResidueNumber();
                }
            }).catch((err) => {
                console.log("handleInviteConfirm -> err", err);
                this.$message.warning('创建失败');
            })
        },
        qrcode (link) {  // 生成二维码
            document.getElementById("qrcode").innerHTML = ""; //清除上次二维码的数据
            let qrcode = new QRCode('qrcode', {
                width: 125,
                height: 125,        // 高度
                text:  link,   // 二维码内容
            })
            let qrcodeImgDom = qrcode._el.children[0]
            return qrcode
        },
        handleSaveImg() { // 下载图片
            console.log('下载图片')
            let a = document.createElement('a')
            a.download = `${this.invite.inviteLink}-${this.invite.user_name}-${this.invite.user_name}.png`
            a.href = this.invite.inviteQrCodeImg;
            a.click();
        },
        handleCopyLink() { // 复制链接
            this.$copyText(this.invite.inviteLink)
                .then(res => {
                    this.$message.success('内容已复制到剪切板')
                })
                .catch(err => {
                    this.$message.warning('复制失败')
                })
        },
        handleDeleteInvite(id) { // 删除邀请
            const h = this.$createElement;
            this.$msgbox({
                title: '确定要删除吗?',
                message: h('p', null, [
                    h('span', null, '删除'),
                    h('strong', null, '邀请记录'),
                    h('span', null, '会将对应的'),
                    h('strong', null, '测评报告'),
                    h('span', null, '也删除'),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.operationApi.Invite.delete(id)
                    .then((res) => {
                        this.$message.success('删除成功!');
                        this.getInviteList();
                    })
                    .catch((err) => {
                        console.log("deleteInvite -> err", err)
                        this.$message.warning('删除失败!');
                    })
            });
        },
        handleDeleteSelect() { // 批量删除
            const h = this.$createElement;
            this.$msgbox({
                title: '确定要批量删除吗?',
                message: h('p', null, [
                    h('span', null, '删除'),
                    h('strong', null, '邀请记录'),
                    h('span', null, '会将对应的'),
                    h('strong', null, '测评报告'),
                    h('span', null, '也删除'),
                ]),
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let flag = 0
                for (let i = 0; i < this.multipleSelection.length; i++) {
                    Core.operationApi.Invite.delete(this.multipleSelection[i].id)
                        .then((res) => {
                            console.log('删除成功')
                        })
                        .catch((err) => {
                            console.log('删除失败',err)
                            flag = 1
                        })
                }
                if (flag) {
                    this.$message.warning('删除失败')
                } else {
                    this.$message.success('删除成功')
                }
                this.getInviteList();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .alert {
        @include flex(row, space-between, center);
        width: 100%;
        height:42px;
        padding: 0 16px;
        box-sizing: border-box;
        border-radius: 4px;
        margin-top: -14px;
        margin-bottom: 16px;
        .text {
            i.el-icon-info {
                margin-right: 8px;
            }
            span {
                color: rgba(0, 0, 0, 0.65);
            }
        }
        .close {
            color: rgba(0,0,0,0.45);
            font-size: 12px;
            &:hover {
                color: rgba(0,0,0,.7);
            }
        }
        &.primary {
            background:#e6f7ff;
            border:1px solid #91d5ff;
            .text {
                i, .number {
                    color: #1890FF;
                }
            }
        }
        &.error {
            background:#FFF1F0;
            border:1px solid #FFA39E;
            .text {
                i, .number {
                    color: #FF4F57;
                }
            }
        }
    }
    .alert-inner {
        margin-left: 10px;
        .text {
            i.el-icon-info {
                margin-right: 8px;
            }
            span {
                color: rgba(0, 0, 0, 0.65);
            }
        }
        &.primary {
            .text {
                i, .number {
                    color: #1890FF;
                }
            }
        }
        &.error {
            .text {
                i, .number {
                    color: #FF4F57;
                }
            }
        }
    }
    .dialog-invite {
        .dialog-content {
            .content-floor {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0px;
                }
                .key {
                    display: block;
                    width: 120px;
                }
            }
            .inviteQrCode {
                width: 100%;
                margin-bottom: 20px;
                .qrcode {
                    width: 125px;
                    height: 125px;
                    margin: 0 auto;
                    padding: 8px;
                    border: 1px solid #E7ECF1;
                    img {
                        height: 100%;
                    }
                }
            }
            .inviteLink {
                text-align: center;
                color: #1F1F1F;
            }
        }
    }
    .status-0 {color: #4d91f3;}
    .status-100 { color: #06B552;}
    .status-200 {color: #8c96a6;}
}
</style>